import "./Style.css"
import React from 'react'
// import Am from "./Assests/am.js"
// import P from "./Assests/p.js"
// import Sm from "./Assests/sm.js"

function Content() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="searchwrapper">
                        <div className="searchbox">
                            <div className="align-items-center d-flex justify-content-between">
                                <div className="d-flex align-items-center d-flex justify-content-between">
                                    <i className="bi bi-gem"></i>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search by Keywords..."
                                    />
                                </div>
                                <div className="d-flex align-items-center d-flex justify-content-between">
                                    <i className="bi bi-geo-alt-fill"></i>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Location"
                                    />
                                </div>

                                <div className="btn">
                                    <i className="bi bi-search"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="service-area">
                <div className="container">
                    <div className="row">
                        <div className="column">
                            <div className="card">
                                <div className="icon-wrapper">
                                    {/* <img src={Am} className="img" alt="true" /> */}
                                </div>
                                <h3 style={{ color: 'black' }}>Access More</h3>
                                <p style={{ color: 'black' }}>Pursue passions, Get things done</p>
                            </div>
                        </div>
                        <div className="column">
                            <div className="card">
                                <div className="icon-wrapper icon-wrapper1">
                                    {/* <i className="bi bi-emoji-heart-eyes-fill"></i> */}
                                    {/* <img src={Sm} className="img" alt="true" /> */}
                                </div>
                                <h3>Help The Planet</h3>
                                <p style={{ color: 'black' }}>Live lighter, Reduce waste</p>
                            </div>
                        </div>
                        <div className="column">
                            <div className="card">
                                <div className="icon-wrapper icon-wrapper2">
                                    {/* <i className="bi bi-emoji-smile-fill"></i> */}
                                    {/* <img src={P} className="img" alt="true" /> */}
                                </div>
                                <h3>Save Money</h3>
                                <p style={{ color: 'black' }}>Buy less. Rent for a fraction of cost</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <hr />
            <section>
                <div className="font-center">
                    <h1>Featured Categories</h1>
                    <div className="container pt-4">
                        <div className="row">

                            <div className="col-md-4 pe-0 ps-2 left-top-img">
                                <img src="images/2.webp" alt="" className=" w-100 mb-2"></img>
                                <h2 className="left-top-img-content">Vehicles</h2>

                                <img src="images/6.webp" alt="" className="w-100 mb-2"></img>
                                <h2 className="right-top-img-content">Cloths</h2>
                            </div>


                            <div className="col-md-4 pe-0 ps-2 right-top-img1">
                                <h2 className="right-top-content1">Film & Photography</h2>
                                <img src="images/c.png" alt="" className="w-100 mb-2"></img>
                            </div>



                            <div className="col-md-4 pe-0 ps-2 right-top-img2">
                                <h2 className="right-top-content2">Electronics</h2>
                                <img src="images/4.webp" alt="" className="w-100 mb-2"></img>


                                <img src="images/3.webp" alt="" className="w-100 mb-2"></img>
                                <h2 className="right-top-img-content2">Home</h2>
                            </div>
                            <div className="btn1 pt-4">
                                <button>View All Categories</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <hr />
            <section>
                <div className="container pt-4">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="row align-items-center">
                                <div className="col-md-3">
                                    <img src="images/a.webp" alt="" className="w-100 mb-2 rounded-corner"></img>

                                    <img src="images/a1.webp" alt="" className="w-100 mb-2 rounded-corner"></img>

                                </div>
                                <div className="col-md-3 about-mid-img">
                                    <img src="images/a2.webp" alt="" className="w-100 mb-2 rounded-corner"></img>

                                    <img src="images/a3.webp" alt="" className="w-100 mb-2 rounded-corner"></img>

                                </div>
                                <div className="col-md-3">
                                    <img src="images/a4.webp" alt="" className="w-100 mb-2 rounded-corner"></img>

                                    <img src="images/a5.webp" alt="" className="w-100 mb-2 rounded-corner"></img>

                                </div>

                                <div className="col-md-3 pt-5">
                                    <img src="images/a6.webp" alt="" className="w-100 mb-2 rounded-corner"></img>

                                </div>

                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-4 pt-5">
                            <h1>About JustRent</h1>
                            <p><b>Convenient to access any item.</b></p>
                            <ul className="custom-list">
                                <li>This is me Vibhor Kulshrestha. CEO & Founder of "Leassy.com". Leassy Rentals has fast become one of India's leading event and party rentals specialists.<br />Large growth has formed mainly from our repeat customers and referrals and a range of large companies/organisations that use us on a monthly basis.<br />Our goal is to offer each and every customer an unbeatable service both in delivery and quality of products</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </section>
            <hr />
            <br />



            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 bg-img-relative">
                            <img src="images/b.png" alt="" className="w-100 mb-2 "></img>
                            <div className="img-content">
                                <h2 className="text-center">List Your Assets</h2>

                                <p>Earn money from your things</p>
                                <div className="btn2 text-center">
                                    <button>Start Earning</button>
                                </div>
                            </div>



                        </div>
                        <div className="col-md-6 bg-img-relative">
                            <img src="images/h.png" alt="" className="w-100 mb-2 "></img>
                            <div className="img-content1">
                                <h2 className="text-center1">Discover Items</h2>
                                <div className="pera">
                                    <p>Get access to anything</p>
                                </div>

                                <div className="searchwrapper searchwrapper1">

                                    <div className="searchbox searchbox1">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <i className="bi bi-gem"></i>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search by Keywords..."
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <i className="bi bi-geo-alt-fill"></i>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Location"
                                                />
                                            </div>
                                            <div className="btn">
                                                <i className="bi bi-search"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />
            {/* <section>
<div className="footer">
  <div className="footer-container">
    <div className="left">
      <div className="location">
        <FaHome size={15} style={{ color: "white", marginRight: "2rem" }} />
        <div>
          <p>C-9,Mahal Yojan,Near RTech Capital Highstreet, Jagatpura</p>
          <p>Jaipur (303806)</p>
          <p>Rajasthan</p>
        </div>
      </div>
      <div className="phone">
        <h4><FaPhone size={15} style={{ color: "white", marginRight: "2rem" }} />
          9828167975, 8005795032</h4> 
      </div>
      <div className="gmail">
        <h4><FaMailBulk size={15} style={{ color: "white", marginRight: "2rem" }} />
          vibhor7975@gmail.com</h4>
      </div>
    </div>

    <div className="right">
      <h4>About the company</h4>
      <p>This is me Vibhor Kulshrestha. CEO & Founder of "Leassy.com". Leassy Rentals has fast become one of India's leading event and party rentals specialists. Large growth has formed mainly from our repeat customers and referrals and a range of large companies/organisations that use us on a monthly basis. Our goal is to offer each and every customer an unbeatable service both in delivery and quality of products.</p>
      <div className="social">
        <FaFacebook size={30} style={{ color: "white", marginRight: "1rem" }} />
        <FaTwitter size={30} style={{ color: "white", marginRight: "1rem" }} />
        <FaLinkedin size={30} style={{ color: "white", marginRight: "1rem" }} />
      </div>
    </div>
  </div>
</div>
</section> */}

            <hr />


        </>
    )
}

export default Content