// import "./Style.css"

// import React from 'react'


// function Header() {
//     return (
//         <>
//             <div className="top-navbar text-center p-2  bg-success">
//                 <p className="m-0 text-light">
//                     {" "}
//                     Lorem ipsum dolor sit amet consectetur adipisicing elit. Error, quasi.
//                 </p>
//             </div>

//             <div className="bg-slider">
//                 <nav className="navbar navbar-expand-lg navbar-light  border-bottom">
//                     <div className="container">
//                         <a href="/" className="navbar-brand">
//                             Justleassy
//                         </a>
//                         <button
//                             className="navbar-toggler"
//                             type="button"
//                             data-bs-toggle="collapse"
//                             data-bs-target="#navbarSupportedContent"
//                             aria-controls="navbarSupportedContent"
//                             aria-expanded="false"
//                             aria-label="Toggle navigation"
//                         >
//                             <span className="navbar-toggler-icon"></span>
//                         </button>
//                         <div
//                             className="collapse navbar-collapse"
//                             id="navbarSupportedContent"
//                         >
//                             <ul className="navbar-nav me-auto mb-2 mb-lg-0">
//                                 <li className="nav-item">
//                                     <a href="nav-link active" aria-current="page">
//                                         List an item
//                                     </a>
//                                 </li>
//                                 <li className="nav-item">
//                                     <a href="nav-link active" aria-current="page">
//                                         Register
//                                     </a>
//                                 </li>
//                                 <li className="nav-item">
//                                     <a href="nav-link active" aria-current="page">
//                                         Log in
//                                     </a>
//                                 </li>
//                             </ul>
//                         </div>
//                     </div>
//                 </nav>

//                 <div className="bg-slider-content">
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-md-7">
//                                 <h1>Rent Anything From Anyone</h1>

//                                 <p>
//                                     Access thousands of items, safely and for sustainable living
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default Header


import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './Style.css'; // Import your global styles

function Header() {
    const navItems = [
        { text: 'List an item', link: '#' },
        { text: 'Register', link: '#' },
        { text: 'Log in', link: '#' },
    ];

    return (
        <>
            <div className="top-navbar text-center p-2  bg-success">
                <p className="m-0 text-light">
                    {" "}
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Error, quasi.
                </p>
            </div>

            <div className="bg-slider">
                <Navbar variant="dark" expand="lg">
                    <div className="container">
                        <Navbar.Brand href="/">Justleassy</Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarSupportedContent" />
                        <Navbar.Collapse id="navbarSupportedContent">
                            <Nav className="me-auto">
                                {navItems.map((item, index) => (
                                    <Nav.Link
                                        href={item.link}
                                        className="nav-link active custom-nav-link"
                                        key={index}
                                    >
                                        {item.text}
                                    </Nav.Link>
                                ))}
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </Navbar>

                <div className="bg-slider-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <h1>Rent Anything From Anyone</h1>
                                <p>
                                    Access thousands of items, safely and for sustainable living
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;
