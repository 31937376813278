import "./Style.css"
import React from 'react'
import Grid from '@mui/material/Grid'


function Footer() {
    return (
        <>
            <section>

                <div className="main"></div>
                <footer id="Footer" style={{ backgroundColor: '#003970', color: 'white' }} className="page-footer font-small stylish-color-dark py-5">
                    <div style={{ backgroundColor: '#003970', color: 'white' }} className="container text-center text-md-left">
                        <div className="row">
                            <div className="col-md-4 mx-auto">
                                {/* <!-- Content --> */}
                                <h5 className="text-uppercase font-weight-bold ">About our Company</h5>
                                <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: '70px' }} />
                                <p>This is me Vibhor Kulshrestha. CEO & Founder of "Leassy.com". Leassy Rentals has fast become one of India's leading event and party rentals specialists. Large growth has formed mainly from our repeat customers and referrals and a range of large companies/organisations that use us on a monthly basis. Our goal is to offer each and every customer an unbeatable service both in delivery and quality of products.</p>
                            </div>
                            <hr className="clearfix w-100 d-md-none" />
                            <div id="link10" className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase font-weight-bold">Products</h6>
                                <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: '70px' }} />
                                <p>
                                    <a href="#!">JustRent</a>
                                </p>
                                <p>
                                    <a href="#!">About</a>
                                </p>
                                <p>
                                    <a href="#!">BrandFlow</a>
                                </p>
                                <p>
                                    <a href="#!">Services</a>
                                </p>
                            </div>
                            <hr className="clearfix w-100 d-md-none" />
                            <div id="link10 link11" className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase font-weight-bold">Useful links</h6>
                                <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: '70px' }} />
                                <p>
                                    <a href="#!">Your Account</a>
                                </p>
                                <p>
                                    <a href="#!">Become an Affiliate</a>
                                </p>
                                <p>
                                    <a href="#!">Shipping Rates</a>
                                </p>
                                <p>
                                    <a href="#!">Help</a>
                                </p>
                            </div>
                            <hr className="clearfix w-100 d-md-none" />
                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                <h6 className="text-uppercase font-weight-bold">Contact</h6>
                                <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: '60px' }} />
                                <p>
                                    <i className="fas fa-home mr-3" ></i>C-9,Mahal Yojan,      Jagatpura, Jaipur Rajasthan</p>
                                <p>
                                    <i className="fas fa-envelope mr-3"></i>vibhor7975@gmail.com</p>
                                <p>
                                    <i className="fas fa-phone mr-3"></i> + 91 9828167975</p>
                                <p>
                                    <i className="fas fa-print mr-3"></i> + 91 9772178631</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    {/* <!-- Call to action --> */}
                    {/* <ul style={{ backgroundColor: '#b3b3cc' }} className="list-unstyled list-inline text-center py-2">
    <li className="list-inline-item">
      <h5 className="mb-1">Register for free</h5>
    </li>
    <li className="list-inline-item">
      <a href="#Contact" className="btn btn-outline-danger btn-rounded">Sign up!</a>
    </li>
  </ul> */}
                    <hr />

                    {/* <!-- Social buttons --> */}
                    <div className="hover-effect1">
                        <ul style={{ backgroundColor: '#003970' }} className="list-unstyled list-inline text-center">
                            <li className="list-inline-item">
                                <a href="https://www.facebook.com/codewithfaraz" title="Facebook"><i className="fa fa-facebook"></i></a>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://www.twitter.com/codewithfaraz" title="Twitter"><i className="fa fa-twitter"></i></a>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://www.instagram.com/codewithfaraz" title="Instagram"><i className="fa fa-instagram"></i></a>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://www.youtube.com/@codewithfaraz" title="Youtube"><i className="fa fa-youtube"></i></a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#." title="Github"><i className="fa fa-github"></i></a>
                            </li>
                        </ul>
                    </div>
                    {/* <!-- Copyright --> */}
                    <div style={{ backgroundColor: '#003970' }} className="footer-copyright text-center py-3">
                        Copyright© 2023: Design and Develop by Mintu Nagar <Grid
                            container
                            spacing={1}
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            alignContent="stretch"
                            wrap="wrap"

                        >

                        </Grid>
                    </div>
                </footer>

            </section>

        </>
    )
}

export default Footer